<template>
  <v-row>
    <v-col cols="12">
      <v-switch :label="$t('active')" v-model="obBrand.active"></v-switch>
    </v-col>

    <v-col cols="12" lg="6">
      <form-field-text v-model="obBrand.name" required />
    </v-col>

    <v-col cols="12" lg="6">
      <form-field-text v-model="obBrand.code" label="code" />
    </v-col>

    <v-col cols="12" lg="6">
      <v-textarea
        :label="$t('description')"
        outlined
        v-model="obBrand.preview_text"
      />
    </v-col>

    <v-col cols="12" lg="6">
      <company-preview />
    </v-col>

    <v-col cols="12" lg="6" xl="4">
      <images-upload lg="12" hide-images v-model="obBrand" />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Vue, Component, VModel } from "vue-property-decorator";
import { Brand } from "@planetadeleste/vue-mc-shopaholic";
import CompanyPreview from "@/modules/companies/components/CompanyPreview.vue";

@Component({
  components: { CompanyPreview },
})
export default class BrandsSettings extends Vue {
  @VModel({ type: Object, default: () => new Brand() })
  obBrand!: Brand;
}
</script>
